// /* eslint-disable import/prefer-default-export */
import classNames from '@/utils/className';
import { ComponentPropsWithoutRef } from 'react';

export const ContainerImage = ({ children, className, ...rest }: ComponentPropsWithoutRef<'figure'>) => {
  return (
    <figure
      className={classNames(
        'flex w-full flex-col gap-y-2 [&_canvas]:block [&_canvas]:h-full [&_canvas]:w-full [&_canvas]:rounded [&_div]:h-full [&_div]:rounded [&_img]:[&_div]:block [&_img]:[&_div]:h-full [&_img]:[&_div]:w-full [&_img]:[&_div]:rounded [&_img]:[&_div]:object-cover',
        className
      )}
      {...rest}>
      {children}
    </figure>
  );
};

export const CaptionImage = ({ children, ...rest }: ComponentPropsWithoutRef<'figcaption'>) => {
  return (
    <figcaption
      className="px-2 pb-0 pt-2 text-center text-xs font-extralight text-mglGray500 [&_a]:cursor-pointer [&_a]:text-mglBlue600 [&_a]:text-opacity-100 [&_a]:no-underline [&_a]:shadow-[inset_0_-8px_0_0_rgb(39_169_225_/_10%)] [&_a]:transition-all [&_a]:duration-500 hover:[&_a]:shadow-[inset_0_-1.15em_0_rgb(39_169_225_/_10%)]"
      {...rest}>
      {children}
    </figcaption>
  );
};
